/**
 * The Root container
 */
import { useAuthorization } from "@bkry/bowline-components/CustomHook";
import EmailConfirmationModal from "@bkry/bowline-components/EmailConfirmationModal";
import { FooterLayout } from "@bkry/bowline-components/FooterLayout";
import { HeaderLayout } from "@bkry/bowline-components/HeaderLayout";
import { ProjectOnboarding } from "@bkry/bowline-components/ProjectOnboarding";
import { WalletChangeModal } from "@bkry/bowline-components/WalletChangeModal";
import { isServer } from "@bkry/bowline-redux";
import {
  getProjectAttributes,
  showProject,
} from "@bkry/bowline-redux/projects";
import { getEnv, keepWalletConnected } from "@bkry/bowline-utils";
import { useSsrEffect } from "@issr/core";
import { useWeb3React } from "@web3-react/core";
import classNames from "classnames";
import { sha256 } from "js-sha256";
import React, { useEffect, useState } from "react";
import Loadable from "react-loadable";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { StickyContainer } from "react-sticky";
import { setLanguage } from "redux-i18n";
import Cookies from "universal-cookie";
import ProjectDesignLoader from "../components/ProjectDesignLoader";
import PasswordUnlockForm from "../components/utils/PasswordUnlockForm";
import FourOFour from "./FourOFour";
import { getWalletAttributes } from "@bkry/bowline-redux/wallets";
import LicenseAlert from "./utils/LicenseAlert";

const Dashboard = Loadable({
  loader: () => import(/* webpackChunkName: "Dashboard" */ "./Dashboard"),
  loading: () => null,
  modules: ["Dashboard"],
});

const Whitelist = Loadable({
  loader: () => import(/* webpackChunkName: "Whitelist" */ "./Whitelist"),
  loading: () => null,
  modules: ["Whitelist"],
});

const EventsRoot = Loadable({
  loader: () =>
    import(/* webpackChunkName: "EventsRoot" */ "./Events/EventsRoot"),
  loading: () => null,
  modules: ["EventsRoot"],
});

const UnlockableContentsRoot = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "UnlockableContentsRoot" */ "./UnlockableContents/UnlockableContentsRoot"
    ),
  loading: () => null,
  modules: ["UnlockableContentsRoot"],
});

const VotingsRoot = Loadable({
  loader: () =>
    import(/* webpackChunkName: "VotingsRoot" */ "./Votings/VotingsRoot"),
  loading: () => null,
  modules: ["Votings"],
});

const PartnerDealsRoot = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "PartnerDeals" */ "./PartnerDeals/PartnerDealsRoot"
    ),
  loading: () => null,
  modules: ["PartnerDeals"],
});

const TokensRoot = Loadable({
  loader: () =>
    import(/* webpackChunkName: "TokensRoot" */ "./Tokens/TokensRoot"),
  loading: () => null,
  modules: ["TokensRoot"],
});

const StaticPage = Loadable({
  loader: () => import(/* webpackChunkName: "StaticPage" */ "./StaticPage"),
  loading: () => null,
  modules: ["StaticPage"],
});

const MintingSectionRoot = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "MintingSection" */ "./Minting/MintingSectionRoot"
    ),
  loading: () => null,
  modules: ["MintingSection"],
});

// DON'T DELETE THE FOLLOWING COMMENT: NEEDED FOR UPDATING THIS FILE WITH THE SCAFFOLD SCRIPT
// new scaffolded loadable imports

/**
 * The Root container used as entry point for the react-router.
 * All routes are lazy-loaded with Loadable.
 */
function Root() {
  const cookieHandler = new Cookies();
  const REACT_APP_PROJECT_ID = getEnv("REACT_APP_PROJECT_ID");
  const REACT_APP_DASHBOARD_REDIRECT = getEnv("REACT_APP_DASHBOARD_REDIRECT");
  const location = useLocation();
  const { account, connector, ...rest } = useWeb3React();
  const [inputPassword, setInputPassword] = useState("");
  const [unlocked, setUnlocked] = useState(false);
  const [showError, setShowError] = useState(false);
  const keepWalletConnection = useSelector(
    (state) => state.wallets.keepWalletConnection
  );
  const { isBlocked } = useAuthorization();
  useEffect(() => {
    if (keepWalletConnection && !account) {
      keepWalletConnected(account, null, connector, rest);
    }
    // console.log(keepWalletConnected, account, null, connector, rest);
  }, [account]);
  if (!isServer) window.account = account;
  const dispatch = useDispatch();
  const dispatchShowProject = () => {
    dispatch(showProject(REACT_APP_PROJECT_ID));
  };

  const walletData = useSelector((state) =>
    getWalletAttributes(state, account)
  );

  const walletConfirmed = useSelector((state) => state.wallets.confirmed);

  useSsrEffect(dispatchShowProject);
  useEffect(dispatchShowProject, [dispatch, REACT_APP_PROJECT_ID]);
  console.log(
    "LOADING PROJECT ROOT.js",
    REACT_APP_PROJECT_ID,
    "IS SERVER:",
    isServer
  );

  const projectData = useSelector((state) =>
    getProjectAttributes(state, REACT_APP_PROJECT_ID)
  );

  const defaultAvailableLocales = useSelector(
    (state) => state.formLocales.defaultAvailableLocales
  );

  const languageOptions =
    projectData?.available_languages || defaultAvailableLocales;
  const defaultLanguage = "en";
  const currentLocale = useSelector((state) => state.i18nState.lang);
  const urlLocale = location.pathname.split("/")?.[1];
  const containsAvailableLanguageParam = languageOptions?.includes(urlLocale);
  useEffect(() => {
    if (currentLocale && urlLocale && currentLocale !== urlLocale) {
      dispatch(setLanguage(urlLocale));
    }
  }, [currentLocale, urlLocale]);

  const languageRedirectCheck = () => {
    // check if the current path contains a language param which is supported
    if (!projectData) return null;
    if (containsAvailableLanguageParam) return null;

    // const isUserLanguageSupported = languageOptions?.includes(currentUser?.locale);
    // const isLanguageSupported = languageOptions?.includes(language);
    const redirectLanguage = currentLocale || defaultLanguage;
    if (
      languageOptions &&
      !location.pathname.startsWith(`/${redirectLanguage}/`)
    ) {
      return (
        <Redirect
          to={{
            pathname: `/${redirectLanguage}${location.pathname}`,
            search: location.search,
            state: {
              accessMode: projectData?.access_mode,
              accessErrorCode: 403,
            },
          }}
        />
      );
    }
    return null;
  };

  useEffect(() => {
    const accessKey = cookieHandler.get("access_key") || "";

    if (sha256(accessKey)?.toString() === projectData?.access_key) {
      setUnlocked(true);
    }
  }, []);

  const updateUnlockedState = (value) => {
    setUnlocked(value);
    cookieHandler.set("access_key", value, {
      expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 7), // 7 days
    });
  };

  return (
    <>
      {walletData?.email_masked && !walletConfirmed && (
        <EmailConfirmationModal />
      )}
      <ProjectDesignLoader />
      <StickyContainer id="Root">
        {projectData?.access_mode === "password" && !unlocked ? (
          <PasswordUnlockForm
            inputPassword={inputPassword}
            setInputPassword={setInputPassword}
            setUnlocked={updateUnlockedState}
            showError={showError}
            setShowError={setShowError}
          />
        ) : (
          <>
            <HeaderLayout />
            {account && (
              <>
                <ProjectOnboarding />
                <WalletChangeModal />
              </>
            )}
            <div
              id={"content"}
              className={classNames(
                {
                  "content--with-sidebar":
                    projectData?.header_layout === "sidebar",
                },
                {
                  "content--with-floating_sidebar":
                    projectData?.header_layout === "floating_sidebar",
                }
              )}
            >
              <LicenseAlert />
              <Switch>
                <Route path="/:locale/403" component={FourOFour} />
                {isBlocked && (
                  <Redirect
                    to={{
                      pathname: "403",
                      search: location.search,
                      state: {
                        accessMode: projectData?.access_mode,
                        accessErrorCode: 403,
                      },
                    }}
                  />
                )}
                {REACT_APP_PROJECT_ID === "UNKOWN" && <Redirect to="/404" />}
                {languageRedirectCheck()}
                {REACT_APP_DASHBOARD_REDIRECT && (
                  <Route path="/:locale/" exact>
                    <Redirect to={{ pathname: REACT_APP_DASHBOARD_REDIRECT }} />
                  </Route>
                )}
                {!REACT_APP_DASHBOARD_REDIRECT && (
                  <Route path="/:locale/" exact component={Dashboard} />
                )}
                <Route path={"/:locale/allowlist"} component={Whitelist} />
                <Route
                  path="/:locale/contents"
                  component={UnlockableContentsRoot}
                />
                <Route path="/:locale/events" component={EventsRoot} />
                <Route path={"/:locale/votings"} component={VotingsRoot} />
                <Route path="/:locale/deals" component={PartnerDealsRoot} />
                <Route path="/:locale/tokens" component={TokensRoot} />
                <Route path="/:locale/p/*" component={StaticPage} />
                <Route path="/:locale/minting" component={MintingSectionRoot} />

                {/* // DON'T DELETE THE FOLLOWING COMMENT: NEEDED FOR UPDATING THIS FILE WITH THE SCAFFOLD SCRIPT */}
                {/* new routes */}

                <Route component={FourOFour} />
              </Switch>
            </div>
            <FooterLayout />
          </>
        )}
      </StickyContainer>
    </>
  );
}

/**
//  * Selects props from the store and passes them to the component
//  * @param {Object} state the store
//  */
// const mapStateToProps = () => ({});
// /**
//  * Binds dispatch method to the actions passed as props to the component
//  */
// const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

/** export the redux connected component and add a frontload call */
export default withRouter(Root);
